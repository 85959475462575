
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function dialogButtonAdaptableRT () {
    return _createElement('div', { 'className': 'cm_search-box-root__dialog_open-button' }, [this.searchBox(function () {
            return _createElement('div', { 'className': 'search cm_searchBox' }, _createElement('div', { 'className': 'cm_search-box_form-container' }, [
                [this.searchInput(function () {
                        return _createElement('div', { 'className': 'search__input w-full input cm_searchInput' }, 'Search by Product, SKU, or Brand');
                    }, {
                        widgetName: 'undefined',
                        items: undefined
                    })],
                _createElement('span', {
                    'className': 'cm_search-box_clear-container',
                    'key': '882'
                }, this.inputNotEmpty ? _createElement('span', {
                    'className': 'cm_search-box_clear',
                    'onClick': this.clearInput,
                    'key': '428'
                }, '\n    ', window.innerWidth > 768 ? '\u2715' : 'Clear', '\n  ') : null),
                _createElement('button', {
                    'type': 'button',
                    'title': 'Search',
                    'aria-label': 'search button',
                    'className': 'cm_search-box_submit search__submit text-current absolute focus-inset start',
                    'data-cm-role': 'add-query',
                    'key': '884'
                }, [_createElement('svg', {
                        'width': '21',
                        'height': '23',
                        'viewBox': '0 0 21 23',
                        'fill': 'currentColor',
                        'aria-hidden': 'true',
                        'focusable': 'false',
                        'role': 'presentation',
                        'className': 'icon',
                        'key': '7880'
                    }, _createElement('path', { 'd': 'M14.398 14.483 19 19.514l-1.186 1.014-4.59-5.017a8.317 8.317 0 0 1-4.888 1.578C3.732 17.089 0 13.369 0 8.779S3.732.472 8.336.472c4.603 0 8.335 3.72 8.335 8.307a8.265 8.265 0 0 1-2.273 5.704ZM8.336 15.53c3.74 0 6.772-3.022 6.772-6.75 0-3.729-3.031-6.75-6.772-6.75S1.563 5.051 1.563 8.78c0 3.728 3.032 6.75 6.773 6.75Z' }))])
            ]), [this.dropdown(function () {
                    function repeatSections1(sections, sectionsIndex) {
                        return [sections(function () {
                                function repeatItems1(items, itemsIndex) {
                                    return [items(function () {
                                            function mergeProps_text(inline, external) {
                                                var res = Object.assign({}, inline, external);
                                                if (inline.hasOwnProperty('style')) {
                                                    res.style = _.defaults(res.style, inline.style);
                                                }
                                                if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                                    res.className = external.className + ' ' + inline.className;
                                                }
                                                return res;
                                            }
                                            function mergeProps_product(inline, external) {
                                                var res = Object.assign({}, inline, external);
                                                if (inline.hasOwnProperty('style')) {
                                                    res.style = _.defaults(res.style, inline.style);
                                                }
                                                if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                                    res.className = external.className + ' ' + inline.className;
                                                }
                                                return res;
                                            }
                                            function onError1_facetValue(e) {
                                                e.target.classList.add('cm_hide');
                                            }
                                            function mergeProps_facetValue(inline, external) {
                                                var res = Object.assign({}, inline, external);
                                                if (inline.hasOwnProperty('style')) {
                                                    res.style = _.defaults(res.style, inline.style);
                                                }
                                                if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                                    res.className = external.className + ' ' + inline.className;
                                                }
                                                return res;
                                            }
                                            return this.template === 'text' ? _createElement('div', mergeProps_text({ 'className': 'cmTemplate_text' }, { dangerouslySetInnerHTML: { __html: this.textHighlight } })) : this.template === 'product' ? _createElement('div', { 'className': 'cmTemplate_product' }, _createElement('div', { 'className': 'image-block' }, _createElement('img', {
                                                'src': this.imageOrDefault(this.resizeImage(this.image || this.image1)),
                                                'alt': 'Image of ' + (this.removeHTML(this.sku) || this.removeHTML(this.title)),
                                                'onError': this.onImageError
                                            })), _createElement('div', { 'className': 'description-block' }, _createElement('div', mergeProps_product({ 'className': 'title' }, { dangerouslySetInnerHTML: { __html: this.title } })), _createElement('div', mergeProps_product({ 'className': 'subtitle' }, { dangerouslySetInnerHTML: { __html: this.custom_vehicle_fitment_subtitle } })), _createElement('div', { 'className': 'price' }, '\n    ', this.price_varies ? `From ${ this.formatPrice(this.price) }` : this.formatPrice(this.price), '\n  '), window.Convermax.config.isB2B && this.default_price && this.price !== this.default_price ? _createElement('div', {
                                                'className': 'msrp-wrapper price',
                                                'key': '612'
                                            }, _createElement('span', { 'className': 'b2b_price_api' }, _createElement('span', { 'className': 'b2b-rrp' }, 'RRP: ', this.formatPrice(this.default_price)), _createElement('span', { 'className': 'percentage-off' }, '(Save ', this.calcDiscount(this.price, this.default_price), '%)'))) : null, this.sku ? _createElement('div', {
                                                'className': 'sku subtitle',
                                                'key': '995'
                                            }, 'SKU: ', this.removeHTML(this.sku)) : null)) : this.template === 'facetValue' ? _createElement('div', { 'className': 'cmTemplate_facetValue' }, this.payload ? _createElement('div', {
                                                'className': 'image-block facet-image-block',
                                                'key': '35'
                                            }, _createElement('img', {
                                                'src': window.Convermax.config.themeAssetsUrl + this.payload + '.png',
                                                'alt': 'Logotype of ' + this.facetValue,
                                                'onError': onError1_facetValue.bind(this)
                                            })) : null, _createElement('div', { 'className': 'description-block' }, _createElement('div', mergeProps_facetValue({ 'className': 'title' }, { dangerouslySetInnerHTML: { __html: this.value } })), this.hitCount ? _createElement('span', {
                                                'className': 'hit-count',
                                                'key': '414'
                                            }, '(', this.hitCount, ')') : null)) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                        }, { count: undefined })];
                                }
                                return this.sectionTitle !== 'Products' ? _createElement('div', {
                                    'className': 'section' + (this.sectionTitle ? ` ${ this.sectionTitle }` : ''),
                                    'key': '5'
                                }, this.sectionTitle ? _createElement('div', {
                                    'className': 'section-title-div',
                                    'key': '149'
                                }, _createElement('div', { 'className': 'item section-title' }, _createElement('b', {}, this.totalHits > 1 ? `${ this.totalHits } ` : '', this.sectionTitle), !!this.vehicleString ? ` for ${ this.vehicleString }` : '')) : null, !this.sectionTitle ? _createElement('div', {
                                    'className': 'section-title-div',
                                    'key': '438'
                                }, _createElement('div', { 'className': 'item section-title' }, _createElement('b', {}, 'Suggestions'))) : null, _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_items' },
                                    _map(this.items, repeatItems1.bind(this))
                                ])) : null;
                            }, { count: undefined })];
                    }
                    function repeatSections2(sections, sectionsIndex) {
                        return [sections(function () {
                                function repeatItems1(items, itemsIndex) {
                                    return [items(function () {
                                            function mergeProps_text(inline, external) {
                                                var res = Object.assign({}, inline, external);
                                                if (inline.hasOwnProperty('style')) {
                                                    res.style = _.defaults(res.style, inline.style);
                                                }
                                                if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                                    res.className = external.className + ' ' + inline.className;
                                                }
                                                return res;
                                            }
                                            function mergeProps_product(inline, external) {
                                                var res = Object.assign({}, inline, external);
                                                if (inline.hasOwnProperty('style')) {
                                                    res.style = _.defaults(res.style, inline.style);
                                                }
                                                if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                                    res.className = external.className + ' ' + inline.className;
                                                }
                                                return res;
                                            }
                                            function onError1_facetValue(e) {
                                                e.target.classList.add('cm_hide');
                                            }
                                            function mergeProps_facetValue(inline, external) {
                                                var res = Object.assign({}, inline, external);
                                                if (inline.hasOwnProperty('style')) {
                                                    res.style = _.defaults(res.style, inline.style);
                                                }
                                                if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                                                    res.className = external.className + ' ' + inline.className;
                                                }
                                                return res;
                                            }
                                            return this.template === 'text' ? _createElement('div', mergeProps_text({ 'className': 'cmTemplate_text' }, { dangerouslySetInnerHTML: { __html: this.textHighlight } })) : this.template === 'product' ? _createElement('div', { 'className': 'cmTemplate_product' }, _createElement('div', { 'className': 'image-block' }, _createElement('img', {
                                                'src': this.imageOrDefault(this.resizeImage(this.image || this.image1)),
                                                'alt': 'Image of ' + (this.removeHTML(this.sku) || this.removeHTML(this.title)),
                                                'onError': this.onImageError
                                            })), _createElement('div', { 'className': 'description-block' }, _createElement('div', mergeProps_product({ 'className': 'title' }, { dangerouslySetInnerHTML: { __html: this.title } })), _createElement('div', mergeProps_product({ 'className': 'subtitle' }, { dangerouslySetInnerHTML: { __html: this.custom_vehicle_fitment_subtitle } })), _createElement('div', { 'className': 'price' }, '\n    ', this.price_varies ? `From ${ this.formatPrice(this.price) }` : this.formatPrice(this.price), '\n  '), window.Convermax.config.isB2B && this.default_price && this.price !== this.default_price ? _createElement('div', {
                                                'className': 'msrp-wrapper price',
                                                'key': '612'
                                            }, _createElement('span', { 'className': 'b2b_price_api' }, _createElement('span', { 'className': 'b2b-rrp' }, 'RRP: ', this.formatPrice(this.default_price)), _createElement('span', { 'className': 'percentage-off' }, '(Save ', this.calcDiscount(this.price, this.default_price), '%)'))) : null, this.sku ? _createElement('div', {
                                                'className': 'sku subtitle',
                                                'key': '995'
                                            }, 'SKU: ', this.removeHTML(this.sku)) : null)) : this.template === 'facetValue' ? _createElement('div', { 'className': 'cmTemplate_facetValue' }, this.payload ? _createElement('div', {
                                                'className': 'image-block facet-image-block',
                                                'key': '35'
                                            }, _createElement('img', {
                                                'src': window.Convermax.config.themeAssetsUrl + this.payload + '.png',
                                                'alt': 'Logotype of ' + this.facetValue,
                                                'onError': onError1_facetValue.bind(this)
                                            })) : null, _createElement('div', { 'className': 'description-block' }, _createElement('div', mergeProps_facetValue({ 'className': 'title' }, { dangerouslySetInnerHTML: { __html: this.value } })), this.hitCount ? _createElement('span', {
                                                'className': 'hit-count',
                                                'key': '414'
                                            }, '(', this.hitCount, ')') : null)) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                        }, { count: undefined })];
                                }
                                return this.sectionTitle === 'Products' ? _createElement('div', {
                                    'className': 'section' + (this.sectionTitle ? ` ${ this.sectionTitle }` : ''),
                                    'key': '5'
                                }, this.vehicleString ? _createElement('div', {
                                    'className': 'section-title-div',
                                    'key': '149'
                                }, _createElement('div', { 'className': 'item section-title' }, 'Selected Vehicle: ', this.vehicleString)) : null, _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_items' },
                                    _map(this.items, repeatItems1.bind(this))
                                ])) : null;
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_autocomplete cm_dropdown' }, _createElement('div', { 'className': 'cm_dropdown-facets' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_sections' },
                        _map(this.sections, repeatSections1.bind(this))
                    ])), _createElement('div', { 'className': 'cm_dropdown-products' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_sections' },
                        _map(this.sections, repeatSections2.bind(this))
                    ]), this.browseAllButton ? [[this.browseAllButton(function () {
                                return _createElement('a', { 'className': 'item button-item cm_browseAllButton' }, 'Browse all ', this.totalHits, ' products');
                            }, {
                                widgetName: 'undefined',
                                items: undefined
                            })]] : null));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'undefined',
            items: undefined
        })]);
}
        export const componentNames = ["cm:searchInput","cm:browseAllButton","cm:dropdown","cm:searchBox"]